import {ElementTiptapPlugin} from "element-tiptap";

require('./bootstrap');

window.Vue = require('vue');

import VueRouter from 'vue-router';
import BootstrapVue from 'bootstrap-vue';
import ToggleButton from 'vue-js-toggle-button'
import Notifications from 'vue-notification'
import VModal from 'vue-js-modal'
import VuePassword from 'vue-password'
import VueInternationalization from 'vue-i18n'
import Locale from './vue-i18n-locales.generated'
// import { ElementTiptapPlugin } from 'element-tiptap';
import functions from './functions';
import store from './store';



const files = require.context('./', true, /\.vue$/i);
files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

Vue.use(VueRouter);
Vue.use(BootstrapVue);
Vue.use(ToggleButton);
Vue.use(Notifications);
Vue.use(VModal);
Vue.use(VuePassword, {
    name: 'Password', // Optional component name.
});
Vue.use(require('vue-truncate'));


import App from './views/app.vue'
import About from './views/about.vue'
import Merch from './views/merch.vue'
import Quiz from './views/quiz.vue'
import Download from './views/download.vue'
import Faq from './views/faq.vue'
import Gallery from './views/gallery.vue'
import Videos from './views/videos.vue'
import News from './views/news.vue'
import NewsShow from './views/newsShow.vue'
import Terms from './views/terms.vue'
//import Issues from './views/issues.vue'
//import Project from './views/project.vue'
import User from './views/user.vue'
import Home from './views/home.vue'

const router = new VueRouter({
    base: '/',
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home,
            meta: {
                title: 'Homepage',
            }
        },
        {
            path: '/about',
            name: 'about',
            component: About,
            meta: {
                title: 'About',
            }
        },
        {
            path: '/collection',
            name: 'merch',
            component: Merch,
            meta: {
                title: 'Collection',
            }
        },
        {
            path: '/quiz',
            name: 'quiz',
            component: Quiz,
            meta: {
                title: 'Quiz',
            }
        },
        {
            path: '/download',
            name: 'download',
            component: Download,
            meta: {
                title: 'Download',
            }
        },
        {
            path: '/faq',
            name: 'faq',
            component: Faq,
            meta: {
                title: 'Faq',
            }
        },
        {
            path: '/gallery/:page?',
            name: 'gallery',
            component: Gallery,
            meta: {
                title: 'Gallery',
            }
        },
        {
            path: '/videos/:id?',
            name: 'videos',
            component: Videos,
            meta: {
                title: 'Videos',
            }
        },
        {
            path: '/news/:page?',
            name: 'news',
            component: News,
            meta: {
                title: 'News',
            }
        },
        {
            path: '/news/show/:id',
            name: 'showNews',
            component: NewsShow,
            meta: {
                title: 'News',
            }
        },
        {
            path: '/terms',
            name: 'terms',
            component: Terms,
            meta: {
                title: 'Service Terms',
            }
        },
        /*{
            path: '/issues',
            name: 'issues',
            component: Issues,
            meta: {
                title: 'Issues',
            }
        },
        {
            path: '/issues/:id',
            name: 'project',
            component: Project,
        },
        {
            path: '/issues/:id/task/:id_task?',
            name: 'task',
            component: Project,
        },*/
        {
            path: '/user/:id',
            name: 'user',
            component: User,
            meta: {
                title: 'User Profile',
            }
        },
        {
            path: "*",
            component: Home,
            meta: {
                title: 'Homepage',
            }
        }
    ],
});

router.afterEach((to, from) => {
    Vue.nextTick( () => {
        let app_name = document.querySelector("meta[name='application-name']").getAttribute("content");
        document.title = to.meta.title ? app_name + ' - ' + to.meta.title : app_name;
        window.scrollTo(0, 0);
    });
});

const lang = document.documentElement.lang.substr(0, 2);
const i18n = new VueInternationalization({
    locale: lang,
    messages: Locale
});

Vue.use(ElementTiptapPlugin, {lang: lang});

window.onload = function() {
    axios.interceptors.response.use(
        response => response,
        error => {
            if (typeof error.response !== 'undefined') {
                if (error.response.status === 419) {
                    axios.post('/app/logout/').then(function (response) {
                        location.reload();
                    });
                }
            }

            return Promise.reject(error);
        }
    )

    const app = new Vue({
        el: '#app',
        i18n,
        components: {
            App
        },
        router,
        store,
    });
};
